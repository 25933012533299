// dataValidation.js
export function validateTitle(title) {
    if (!title) {
      return { valid: false, message: "Suggested title cannot be empty" };
    }
    return { valid: true };
  }
  
  export function validateDescription(description) {
    if (!description) {
      return { valid: false, message: "Suggested description cannot be empty" };
    }
    return { valid: true };
  }
  
  export function validateSeverity(severity) {
    if (!severity) {
      return { valid: false, message: "Severity cannot be empty" };
    }
    if (!["1", "2", "3", "4", "5"].includes(severity)) {
      return { valid: false, message: "Severity should be between 1 to 5" };
    }
    return { valid: true };
  }
  
  export function validateJustification(justification) {
    if (!justification) {
      return { valid: false, message: "Justification cannot be empty" };
    }
    return { valid: true };
  }
  